import * as Sentry from "@sentry/astro";

Sentry.init({
  dsn: "https://1ad1ee235ab80b8eea38454c910e4e7d@o4506771326435328.ingest.sentry.io/4506805009580032",
  
  integrations: [
    // Tracing Origins are required so that Google Analytics does not through a Cors error.
    // https://github.com/getsentry/sentry-javascript/issues/5868
    Sentry.browserTracingIntegration({
      tracingOrigins: ['/^https:\/\/explra.com/', '/^https:\/\/api.explra.com/',]
    }),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 0.8, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  // Enabling this will trigger CORS issues, adds `baggage` header
  //tracePropagationTargets: ["localhost", /^https:\/\/api\.explra\.com/],
  // Session Replay
  replaysSessionSampleRate: 0.8, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});
